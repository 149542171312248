.app-options {
    width:80%;
    margin-left:200px
}

.nlp-image {
    max-width: 100px;
}

.item-layout-header {
    font-size: 0.5em;
    font-weight: bolder;
}

.input-text {
    width: 500px;
    height: 500px;
}

.dot {
    height: 25px;
    width: 25px;
    /* background-color: rgb(190, 34, 34); */
    border-radius: 50%;
    display: inline-block;
  }

  .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .form-group label {
    margin-right: 20px;
  }